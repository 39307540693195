import React, { useState, useEffect } from 'react'
import cascading from '../assests/css/all.module.css'
import Grid from '@mui/material/Grid';
import Sidebar from '../common/Sidebar';
import { Col, Nav, Row, Tab, ButtonGroup, Button, Form } from 'react-bootstrap';
import Orderbooktable from '../components/orderbooktable'
import { useDispatch, useSelector } from 'react-redux';
import { getArbitrages, Getmarketdepth ,Getorderbook } from '../api/trade.axios';
// import { socket } from '../socket';
import { isEmpty } from '../actions/common';

function Orderbook() {
    const Arbitrages = useSelector(state => state?.redux.Arbitrages)
    const dispatch = useDispatch()
    const [opertinuties, setOpertinuties] = useState([])
    const [symbols, setSymbols] = useState([])
    const [marketdata, SetMarketdata] = useState([])
    const [streamdatas , setStreamdata]=useState('')
    const [depthdata,setDepthdata]=useState({})
    var datas ={};
    var sockets;
    // var intervel;
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        getArbitage()
    }, []);

    const getArbitage = async () => {
        let sendData = {
            pair: "", // optional
            pagesize: 50,
            pagenumber :1
        }
        let res = await getArbitrages(sendData)
        console.log("dispatch", res);
        let oppetinuties = res?.data?.map((val) => {
            return {
                show: `${val?.pair1?.quoteAsset}/${val?.pair2?.sendData?.side == "BUY" ? val?.pair2?.quoteAsset : val?.pair2?.baseAsset}/${val.pair3?.baseAsset}`,
                pairs: val.combination
            }
        })
        getDepth(oppetinuties?.[0]?.pairs,0)
        setOpertinuties(oppetinuties)
        dispatch({
            type: "Arbitrages",
            data: { oppetinuties }
        })
    }

    const getDepth = async (data,id) => {
        console.log("sdsd", data);
        let sendData ={}
        setSymbols(data)
        

        let Marketdepths={}
         await Promise.all(data?.map(async (val) => {
            let data = await Getmarketdepth(val)
            Marketdepths[val.toLowerCase()] = data.data
            // return data.data
        }))
        console.log("Marketdepths", Marketdepths);
        SetMarketdata(Marketdepths)


        // let depths ={}
        // data.map((val)=>{
        //     depths[val.toLowerCase()] = {bids :[] , asks : []}
        // })
        // setDepthdata(depths)
        // sendData.streams = data?.map((val) =>(`${String(val).toLowerCase()}@depth10@1000ms`)).join('/')
        // setStreamdata(sendData.streams)
        // if(!isEmpty(streamdatas)){
        //     sendData[`unstreams`] = streamdatas
        // }
        // let orderdata = await Getorderbook(sendData)

    }
   
  console.log('Marketeteelog-->',marketdata)


    return (
        <div className={`${cascading.innerpages}`}>

            <Grid container >
                <Grid item xl={2} lg={2} md={12} sm={12} xs={12}>
                    <Sidebar />
                </Grid>
                <Grid item xl={10} lg={10} md={12} sm={12} xs={12} >
                    <div className={`${cascading.innercontent} ${cascading.apipage}`}>
                        <div className={`container ${cascading.custom_container}`}>
                            <Row>
                                <Col md={4}>
                                    <Form.Select aria-label="Default select example" onChange={(e) => getDepth(e.target.value.split(","),e.target.id)}>
                                        {/* <option>Open this select menu</option> */}
                                        {
                                            opertinuties?.length > 0 && opertinuties?.map((val,i) => (
                                                <option id={i} value={val.pairs}>{val.show}</option>
                                            ))
                                        }
                                        {/* <option value="1">Binance</option>
                                        <option value="2">Binance2</option>
                                        <option value="3">Binance3</option> */}
                                    </Form.Select>
                                </Col>
                            </Row>
                            <div className={`${cascading.tablelist} ${cascading.numtablelist} mt-4 pt-3 px-3 pb-3 mb-5`}>
                                {marketdata && <Orderbooktable symbols={symbols} data={marketdata} depthdata={depthdata}/>}
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default Orderbook