import React, { useState, useEffect } from "react";
import cascading from "../assests/css/all.module.css";
import Grid from "@mui/material/Grid";
import Sidebar from "../common/Sidebar";
import { Col, Nav, Row, Tab, ButtonGroup, Button } from "react-bootstrap";
import Historytable from "../components/historytable";
import Ontrade from "../components/ontradetable";
import { toast } from "react-hot-toast";
import { fetchtickers ,Canceltrade } from "../api/trade.axios";
import { socket } from "../socket";
import {useSelector} from 'react-redux'

function Arbitrages() {
  const user = useSelector(state => state?.redux?.userdata)
  const [pagesize, setpagesize] = useState(10);
  const [pagenumber, setpagenumber] = useState(1);
  const [tradedata, Settradedata] = useState([]);
  const [hideloader, Sethideloader] = useState(true);
  const [loading, setloading] = useState(false);
  const [values, Setvalues] = useState({});
  const [tab,setTab]=useState('opportunity')

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    tickerrr(tab,pagenumber, pagesize);
  }, [pagesize, pagenumber,tab]);

  useEffect(()=>{

    socket.on('tradeupdate',()=>{
      if( tab == 'ontrade' || tab == "canceled" ){
        console.log('socketcall-->',)
        tickerrr(tab,pagenumber, pagesize);
      }
    })

  },[ socket ])
  const onloadmore = () => {
    let currentpage = pagenumber + 1;
    setpagenumber(currentpage);
  };

  const cancelorder = async(data)=>{
    console.log('cancelorder-->',data)
    toast.promise(Canceltrade({...data,"apikey" : user?.apikey , 'secret' : user?.securitykey }), {
        loading: `${data.symbol} order is canceling`,
        success: "Successfully Cancelled ",
        error: "Something went Wrong please try again",
      })
  
  }

  async function tickerrr(tab,pagenumber, pagesize) {
    let payload = {
      userId : user?.userid,
      pagenumber: pagenumber,
      pagesize: pagesize,
      ...(tab == 'ontrade' ? {status : "pending"} : tab == "canceled" ? {status : tab.toUpperCase() } : {} )
    };
    const res = await fetchtickers(payload);
console.log('res-->',res,payload)
    if (res?.status) {
      console.log("jjj", pagenumber);
      console.log('SettradedataSettradedataifff')
        Settradedata(pagenumber === 1 ? res?.data?.tradedata : [...tradedata, ...res?.data?.tradedata]);
      if (!(res?.data?.tradedata?.length === pagesize)) {
        Sethideloader(false);
      } 
      Setvalues(res?.data?.data);
    } else {
      Setvalues(res?.data?.data);
      Settradedata(res?.data?.tradedata);
      console.log('SettradedataSettradedataelseee')
    }

    console.log("jskhgdkjabsaxdba", res);
    setTimeout(() => {
      setloading(false);
    }, 500);
  }

  return (
    <div className={`${cascading.innerpages}`}>
      <Grid container>
        <Grid item xl={2} lg={2} md={12} sm={12} xs={12}>
          <Sidebar />
        </Grid>
        <Grid item xl={10} lg={10} md={12} sm={12} xs={12}>
          <div className={`${cascading.innercontent} ${cascading.apipage}`}>
            <div className={`container ${cascading.custom_container}`}>
              <p className={`${cascading.maintitle} text-start pt-2`}>
                History
              </p>
              <Row className="mb-4">
                <Col xl={3} lg={4} md={4} sm={6} className="mt-3">
                  <div className={`${cascading.dashborder} py-3 px-3`}>
                    <h5 className={`${cascading.whtclr}`}>Invested amount</h5>
                    <p className={`${cascading.whtclr} ${cascading.f14} mb-0`}>
                      {values?.totalInvestmentAmt
                        ? values?.totalInvestmentAmt
                        : "0 "}
                      USDT
                    </p>
                  </div>
                </Col>
                <Col xl={3} lg={4} md={4} sm={6} className="mt-3">
                  <div className={`${cascading.dashborder} py-3 px-3`}>
                    <h5 className={`${cascading.whtclr}`}>Average profit %</h5>
                    <p className={`${cascading.whtclr} ${cascading.f14} mb-0`}>
                      {values?.averageProfitAmt
                        ? values?.averageProfitAmt
                        : "0 "}
                      USDT
                    </p>
                  </div>
                </Col>
                <Col xl={4} lg={4} md={4} sm={6} className="mt-3">
                  <div className={`${cascading.dashborder} py-3 px-3`}>
                    <h5 className={`${cascading.whtclr}`}>Total profit</h5>
                    <p className={`${cascading.whtclr} ${cascading.f14} mb-0`}>
                      {values?.totalProfitAmt ? values?.totalProfitAmt : "0 "}
                      USDT
                    </p>
                  </div>
                </Col>
              </Row>

              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="order"
              >
                <Row className="mb-5">
                  <Col sm={12}>
                    <Nav variant="pills" className="flex-row ms-3">
                      {/* <Nav.Item>
                        <Nav.Link eventKey="opportunity" onClick={()=>setTab('opportunity')}>Opportunity</Nav.Link>
                      </Nav.Item> */}
                      <Nav.Item>
                        <Nav.Link eventKey="order" onClick={()=>setTab('oporderportunity')}>Order</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="trade" onClick={()=>setTab('trade')}>Trade</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="ontrade" onClick={()=>setTab('ontrade')}>Ongoing</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="canceled" onClick={()=>setTab('canceled')}>Cancelled</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={12}>
                    <Tab.Content>
                      {/* <Tab.Pane
                        className={`${cascading.tablelist}`}
                        eventKey="opportunity"
                      >
                        <Historytable tradedata={tradedata} />
                      </Tab.Pane> */}
                      <Tab.Pane
                        className={`${cascading.tablelist}`}
                        eventKey="order"
                      >
                        <Historytable tradedata={tradedata} />
                      </Tab.Pane>
                      <Tab.Pane
                        className={`${cascading.tablelist}`}
                        eventKey="trade"
                      >
                        <Historytable tradedata={tradedata} />
                      </Tab.Pane>
                      <Tab.Pane
                        className={`${cascading.tablelist}`}
                        eventKey="ontrade"
                      >
                        <Ontrade tradedata={tradedata} tab={tab} cancel={(e)=>cancelorder(e)} />
                      </Tab.Pane>
                      <Tab.Pane
                        className={`${cascading.tablelist}`}
                        eventKey="canceled"
                      >
                        <Ontrade tradedata={tradedata} tab={tab}/>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
              <div className="text-center">
                {/* { loading ? */}
                {hideloader && (
                  <Button
                    className={`d-flex mx-auto text-center py-2 px-3 ${cascading.whtclr} ${cascading.themebtn}`}
                    disabled={loading}
                    onClick={onloadmore}
                  >
                    {!loading ? (
                      "Load More"
                    ) : (
                      <>
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        >
                          {" "}
                        </span>
                        <p className="ml-3">Loading</p>
                      </>
                    )}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Arbitrages;
