import React, { useState, useEffect } from 'react'
import cascading from '../assests/css/all.module.css'
import Grid from '@mui/material/Grid';
import Sidebar from '../common/Sidebar';
import { Col, Nav, Row, Tab , Button } from 'react-bootstrap';
import Mlutiarbicard from '../components/multiarbicard'
import config from '../config'
import { Encryptdata } from '../actions/encode_decode';
import { getArbitrages } from '../api/trade.axios';

function Multiarbi() {

useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
const [pagesize , setpagesize] = useState(20)
const[arbicard,setArbicard] = useState([])
const [pagenumber , Setpage] = useState(1);
const [hideloader,Sethideloader] = useState(true)
const [loading , setloading ] = useState(false)
const[loadpage , setpageloading]= useState(false)
 useEffect(()=>{
   getArbitage()
 },[pagenumber])

 const getArbitage = async ()=>{
    setloading(true)
    
    let sendData = {
        pair : "", // optional
        page : 0 ,
        pagesize : pagesize , 
        pagenumber: pagenumber
    }
    let res = await getArbitrages(sendData)
    console.log('logooo',res)
let data = res.data
    if(pagenumber === 1 ){
        setArbicard(data)
    }
   if(pagenumber > 1){
    setArbicard([...arbicard , ...data])
   }

// if(res.data.length < pagesize){
//     Sethideloader(false)
// }
setpageloading(false)
setloading(false)
setpageloading(true)
 }

//  const mystyle = {}

 const onloadmore = ()=>{
    let currentpage = pagenumber + 1
    Setpage(currentpage)
}


    return (
        <div className={`${cascading.innerpages}`}>

            <Grid container>
                <Grid item xl={2} lg={2} md={12} sm={12} xs={12}>
                    <Sidebar />
                </Grid>
            
                <Grid item xl={10} lg={10} md={12} sm={12} xs={12} >

              

                    <div className={`${cascading.innercontent} ${cascading.apipage}`}>
                        <div className={`container ${cascading.custom_container}`}>
                        { loadpage ?
                            <Row>
                                { arbicard && arbicard.length > 0 && arbicard.map((e,i)=>
                                <Col md={6} lg={4} className='mt-3 mb-3 px-1 px-sm-2'>
                                    <Mlutiarbicard data={e} pair1={e.pair1} pair2={e.pair2} pair3={e.pair3} type={e.type.split('_')} />
                                </Col>
                                )}


<div className='text-center'>
                            {/* { loading ? */}
                            { hideloader &&    <Button className={`d-flex mx-auto text-center py-2 px-3 ${cascading.whtclr} ${cascading.themebtn}`} disabled={loading}
                         onClick={onloadmore}
                         >
                            { !loading ?  "Load More" :
                         <>
                         <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true">  </span>
                          <p className='ml-3'>Loading</p> 
                          </> }
                         </Button>  }  
                        </div>
                            </Row> :
               <div className={`${cascading.height100 } d-flex justify-content-center align-items-center`}>
                     <span class="loader d-flex justify-content-center"></span></div>  }
              
                    
                        </div>
                    </div>       
                </Grid>

              


            </Grid>
        </div>
    )
}

export default Multiarbi