import React, {useState, useEffect} from 'react'
import cascading from '../assests/css/all.module.css'
import Grid from '@mui/material/Grid';
import Sidebar from '../common/Sidebar';
import { Col, Nav, Row, Tab, ButtonGroup, Button } from 'react-bootstrap';
import Tickertable from '../components/tickertable'
import {fetchtickers} from '../api/trade.axios'
import {useSelector} from 'react-redux';

function Arbitrages() {
    const user = useSelector(state => state?.redux?.userdata)
const [pagesize , setpagesize] = useState(10)
const [pagenumber , setpagenumber] = useState(1)
const [tradedata , Settradedata]= useState("")
const [hideloader,Sethideloader] = useState(true)
const [loading , setloading ] = useState(false)
const[values , Setvalues ] = useState({})
    useEffect(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }, []);

useEffect(()=>{
    tickerrr( pagenumber , pagesize)
},[pagesize , pagenumber])

const onloadmore = ()=>{
    let currentpage = pagenumber + 1
    setpagenumber(currentpage)
}

async function tickerrr(pagenumber , pagesize){
    setloading(true) 

let payload ={
    userId : user?.userid,
    pagenumber : pagenumber ,
    pagesize : pagesize ,
    status : 'COMPLETED'
}

    const res = await fetchtickers(payload)
console.log('fetchtickers-->', res?.data?.tradedata)
    if(res?.status){
        console.log("jjj" , pagenumber)
        if(res?.data?.tradedata?.length ===  pagesize){
            if(pagenumber === 1 ){
                Settradedata(res?.data?.tradedata)
    
            }else{
                let data = res?.data?.tradedata
                Settradedata([...tradedata , ...data])
            }
        }else{
            let data = res?.data?.tradedata
            Settradedata([...tradedata , ...data])
            Sethideloader(false)
        }
     
        Setvalues(res?.data?.data)
    }else{
        Setvalues(res?.data?.data)
        Settradedata(res?.data?.tradedata)
    }
    
    console.log("jskhgdkjabsdba" , res)
    setTimeout(() => {
    setloading(false)
        
    }, 500);
}

    return (
        <div className={`${cascading.innerpages}`}>

            <Grid container >
                <Grid item xl={2} lg={2} md={12} sm={12} xs={12}>
                    <Sidebar />
                </Grid>
                <Grid item xl={10} lg={10} md={12} sm={12} xs={12} >
                    <div className={`${cascading.innercontent} ${cascading.apipage}`}>
                        <div className={`container ${cascading.custom_container}`}>

                            <p className={`${cascading.maintitle} text-start pt-2`}>Ticker</p>
                            <div className='text-end'>
                                {/* <ButtonGroup aria-label="Basic example" className='mb-3'>
                                    <Button variant="secondary">1 day</Button>
                                    <Button variant="secondary">7 days</Button>
                                    <Button variant="secondary">30 days</Button>
                                    <Button variant="secondary">All</Button>
                                </ButtonGroup> */}
                            </div>
                            <Row className='mb-4'>
                                <Col xl={3} lg={4} md={4} sm={6} className='mt-3'>
                                    <div className={`${cascading.dashborder} py-3 px-3`}>
                                        <h5 className={`${cascading.whtclr}`}>Invested amount</h5>
                                        <p className={`${cascading.whtclr} ${cascading.f14} mb-0`}>{values?.totalInvestmentAmt ? values?.totalInvestmentAmt : "0"} USDT</p>
                                    </div>
                                </Col>
                                <Col xl={3} lg={4} md={4} sm={6} className='mt-3'>
                                    <div className={`${cascading.dashborder} py-3 px-3`}>
                                        <h5 className={`${cascading.whtclr}`}>Average profit %</h5>
                                        <p className={`${cascading.whtclr} ${cascading.f14} mb-0`}>{values?.averageProfitAmt ? values?.averageProfitAmt : "0"}USDT</p>
                                    </div>
                                </Col>
                                <Col xl={4} lg={4} md={4} sm={6} className='mt-3'>
                                    <div className={`${cascading.dashborder} py-3 px-3`}>
                                        <h5 className={`${cascading.whtclr}`}>Total profit</h5>
                                        <p className={`${cascading.whtclr} ${cascading.f14} mb-0`}>{values?.totalProfitAmt ? values?.totalProfitAmt : "0"} USDT</p>
                                    </div>
                                </Col>
                            </Row>


                            <div className={`${cascading.tablelist} pt-0 pb-2 mb-5`}>
                                <Tickertable tradedata={tradedata}/>
                            </div>


                        <div className='text-center'>
                            {/* { loading ? */}
                            { hideloader &&    <Button className={`d-flex mx-auto text-center py-2 px-3 ${cascading.whtclr} ${cascading.themebtn}`} disabled={loading}
                         onClick={onloadmore}
                         >
                            { !loading ?  "Load More" :
                         <>
                         <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true">  </span>
                          <p className='ml-3'>Loading</p> 
                          </> }
                         </Button>  }  
                        </div>

                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default Arbitrages