import { Route, Navigate ,Routes } from "react-router-dom";
import Settings from "../views/Settings";
import Apibinding from "../views/apibinding";
import Arbitrages from "../views/arbitrages";
import Multiarbi from "../views/multiarbi";
import History from "../views/history";
import Ticker from "../views/ticker";
import Orderbook from "../views/orderbook";

import Loginpage from '../views/login.jsx';
import Regsiterpage from '../views/register.jsx';
import Forgotpassword from '../views/forgotpassword.jsx';
import Landing from '../views/landing.jsx';
import Contact from '../views/contact.jsx';
import Terms from '../views/terms.jsx';
import Aboutus from '../views/aboutus.jsx';
import Pricing from "../views/pricing.jsx";

const routes = [{
    path: '/arbitrages',
    component: <Arbitrages/>,
  },
  {
    path: '/multiarbi',
    component: <Multiarbi/>,
  },
  {
    path: '/history',
    component: <History/>,
  },
  {
    path: '/api',
    component: <Apibinding/>,
  },
  {
    path: '/ticker',
    component: <Ticker/>,
  },
  {
    path: '/orderbook',
    component: <Orderbook/>,
  },
  {
    path: '/settings',
    component: <Settings/>,
  },
  {
    path: '/login',
    component: <Loginpage/>,
  },
  {
    path: '/register',
    component: <Regsiterpage/>,
  },
  {
    path: '/register:ref',
    component: <Regsiterpage/>,
  },
  {
    path: '/forgotpassword',
    component: <Forgotpassword/>,
  },
  {
    path: '/',
    component: <Landing/>,
  },
  {
    path: '/contact',
    component: <Contact/>,
  },
  {
    path: '/terms',
    component: <Terms/>,
  },
  {
    path: '/pricing',
    component: <Pricing/>,
  },
  {
    path: '/aboutus',
    component: <Aboutus/>,
  },
 ];


//  export default function Routing ({auth}) {
//   var routing = routes.map(({ path, component }, key) =>
//   <Route
//       exact
//       path={path}
//       element={
//         ['/login', '/register', '/terms', '/contact', '/'].includes(path) ? component :
//         auth ? component :
//             (<Navigate to="/login" replace />)
//       }
//       key={key}
//     />
//    )
 
//   return (
//     // <Routes>
//       // {
//         routing
//       // }</Routes>
    
//   )
//  }
 
 export default routes;