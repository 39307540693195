import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Col,
  Nav,
  Row,
  Tab,
  ButtonGroup,
  Button,
  InputGroup,
  Form,
  Table,
} from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import cascading from "../assests/css/all.module.css";

export default function DataTable(props) {
  const { tradedata } = props;
  // const [rows , setrows] = React.useState([])
  // const [data , setdata]= React.useState()
  // const rows = [
  //     { id: 1, created: '04-10-2023   16:45:51', updated: '04-10-2023   16:58:07', opportunity: '651sf213df51266641212dfsd', order: '651sf213df51266641212dfsd', pair: 'BUSD/USDT' },
  //     { id: 2, created: '04-10-2023   16:45:51', updated: '04-10-2023   16:58:07', opportunity: '651sf213df51266641212dfsd', order: '651sf213df51266641212dfsd', pair: 'BUSD/USDT' },
  //     { id: 3, created: '04-10-2023   16:45:51', updated: '04-10-2023   16:58:07', opportunity: '651sf213df51266641212dfsd', order: '651sf213df51266641212dfsd', pair: 'BUSD/USDT' },
  //     { id: 4, created: '04-10-2023   16:45:51', updated: '04-10-2023   16:58:07', opportunity: '651sf213df51266641212dfsd', order: '651sf213df51266641212dfsd', pair: 'BUSD/USDT' },
  //     { id: 5, created: '04-10-2023   16:45:51', updated: '04-10-2023   16:58:07', opportunity: '651sf213df51266641212dfsd', order: '651sf213df51266641212dfsd', pair: 'BUSD/USDT' },
  //     { id: 6, created: '04-10-2023   16:45:51', updated: '04-10-2023   16:58:07', opportunity: '651sf213df51266641212dfsd', order: '651sf213df51266641212dfsd', pair: 'BUSD/USDT' },
  //     { id: 7, created: '04-10-2023   16:45:51', updated: '04-10-2023   16:58:07', opportunity: '651sf213df51266641212dfsd', order: '651sf213df51266641212dfsd', pair: 'BUSD/USDT' },
  //     { id: 8, created: '04-10-2023   16:45:51', updated: '04-10-2023   16:58:07', opportunity: '651sf213df51266641212dfsd', order: '651sf213df51266641212dfsd', pair: 'BUSD/USDT' },
  //     { id: 9, created: '04-10-2023   16:45:51', updated: '04-10-2023   16:58:07', opportunity: '651sf213df51266641212dfsd', order: '651sf213df51266641212dfsd', pair: 'BUSD/USDT' },
  // ];

  // React.useEffect(()=>{
  //     if(tradedata?.length > 0){
  //         setdata(tradedata)
  //     }

  // },[tradedata])

  // function setdata(tradedata){
  let rows = [];
  // SetLoader(false);
  for (let i = 0; i < tradedata.length; i++) {
    for (let j = 0; j < tradedata[i].trade.length; j++) {
      let data = {
        id: tradedata[i]?.trade[j]?.orderid,
        created: tradedata[i]?.createdAt,
        updated: tradedata[i]?.updatedAt,
        opportunity: tradedata[i]?._id,
        order: tradedata[i]?.trade[j]?.orderid,
        spend : `${tradedata[i]?.trade[j]?.spend} ${j == 0 ? tradedata[i]?.pair?.split('/')[0] : j==1 ? tradedata[i]?.pair?.split('/')[1] : tradedata[i]?.pair?.split('/')[2] }`,
        buy : `${tradedata[i]?.trade[j]?.buy} ${j == 0 ? tradedata[i]?.pair?.split('/')[1] : j==1 ? tradedata[i]?.pair?.split('/')[2] : tradedata[i]?.pair?.split('/')[0] }`,
        side : tradedata[i]?.trade[j]?.orderdetails?.side,
        pair: tradedata[i]?.trade[j]?.pair,
      };
      rows.push({ ...data });
    }
  }
  console.log("tradedatatradedata", tradedata);

  //    setrows([...group])
  //    setTimeout(()=>{
  // SetLoader(true);
  // },[1000])

  // }

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "created", headerName: "Created At", width: 200 },
    { field: "updated", headerName: "Updated At", width: 200 },
    { field: "opportunity", headerName: "Opportunity Id", width: 200 },
    {
      field: "order",
      headerName: "Order Id",
      width: 200,
      // renderCell: (params) =>
      // <p >{console.log('paramssssssss',params?.row)}{params?.row?.order}</p>
    },
    { field: "pair", headerName: "Pair", width: 200 },
  ];

  return (
    <>
      {/* <Row className='px-3 pt-4'>
                <Col xs={10} sm={6} md={5} lg={4} className='ms-auto'>
            <InputGroup className="mb-3">
                <Form.Control
                    placeholder="Filter in opportunities..."
                    aria-label="Filter in opportunities..."
                    aria-describedby="basic-addon2"
                />
                <InputGroup.Text id="basic-addon2"><FaSearch /></InputGroup.Text>
            </InputGroup>
            </Col>
            </Row> */}

      {/* <div style={{ height: 400, width: '100%' }}>
            {
                Loader &&
                <>{ rows.length > 0 ?
                <DataGrid
                sx={{
                    borderRadius: "20px",
                    border: "1px solid #515151", // also tried setting to none 
                }}
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 100 },
                    },
                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection={false}
                columnVisibilityModel={{
                    id: false,
                }}
            />: <h2 class='text-center mt-auto no_data_center'>No data found</h2>}
                </> 

            }
            
        </div> */}

      <div>
        <div className="alltables">
          <Table responsive>
            <thead>
              <th>Created at</th>
              <th>Updated at</th>
              <th>Opportunity Id</th>
              <th>Order Id</th>
              <th>Type</th>
              <th>Spend</th>
              <th>Buy</th>
              <th>Pair</th>
            </thead>
            <tbody>
              {rows.length > 0 ? (
                <>
                  {" "}
                  {rows?.map((data, index) => (
                    <tr id={index}>
                      <td>
                        {Date(data?.created).replace(
                          "(India Standard Time)",
                          ""
                        )}
                      </td>
                      <td>
                        {console.log("asdasgxxxd", data)}

                        <p>
                          {Date(data?.updated).replace(
                            "(India Standard Time)",
                            ""
                          )}
                        </p>
                      </td>
                      <td>{data?.opportunity}</td>
                      <td>
                        <p className={`${cascading.greencolor}`}>
                          {" "}
                          {data?.order}{" "}
                        </p>
                      </td>
                      <td>
                        <p className={`${cascading.greencolor}`}>
                          {" "}
                          {data?.side}{" "}
                        </p>
                      </td>
                      <td>
                        <p className={`${cascading.greencolor}`}>
                          {" "}
                          {data?.spend}{" "}
                        </p>
                      </td>
                      <td>
                        <p className={`${cascading.greencolor}`}>
                          {" "}
                          {data?.buy}{" "}
                        </p>
                      </td>
                      <td>
                        <p className={`${cascading.greencolor}`}>
                          {" "}
                          {data?.pair}{" "}
                        </p>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <h2 class="text-center mt-auto no_data_center"> no data </h2>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
}
