import React ,{useEffect, useState} from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import routes from './Routes/routes.jsx'
import 'bootstrap/dist/css/bootstrap.css';
import "./App.css";
import {useSelector} from 'react-redux'


function App() {
  const [routeComponents,setroute]=useState([])
  // var routeComponents;
  const user = useSelector(state => state?.redux?.userdata)

  const isAuthenticated = () => {
    return (localStorage.getItem('usertoken') !== null || sessionStorage.getItem('usertoken') !== null);
  };

   useEffect (()=>{
    let routeComponents = routes.map(({ path, component }, key) =>
    { 
    return (<Route
       exact
       path={path}
       element={
         ['/login', '/register', '/terms', '/contact', '/'].includes(path) ? component :
           isAuthenticated() ? component :
             (<Navigate to="/login" replace />)
       }
       key={key}
     />)
   }
   ) 
   setroute(routeComponents) 
   },[user])
    
  
  
  
  return (

    

    <BrowserRouter>
      <Routes>
        {/* <Approutes auth={localStorage.getItem('usertoken') !== null}/> */}
        {routeComponents}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
