import React, { useState ,useEffect } from 'react'
import cascading from '../assests/css/settingcard.module.css'
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
// import { socket } from '../socket';
import { isEmpty } from '../actions/common';

function Orderbooktable({symbols,data,depthdata}) {
  const [mapdata,setmapdata] = useState(data)
    const [redcolor, setRedcolor] = useState([
        { "id": "1" }, { "id": "2" }, { "id": "3" }, { "id": "4" }
    ])
   console.log('log-->',data,mapdata)

   useEffect(()=>{
    setmapdata(data)
   },[depthdata,data])
        return (
        <>
            <div>

                <div className={`${cascading.allrowss} row`}>
                    <div className='col-11 col-sm-6 col-md-4'>
                    <div className='text-center'>
                                    <p className={`${cascading.whtclr} mb-0`}>Binance</p>
                                    <p className={`${cascading.grayclr} ${cascading.f12} mb-3`}>{symbols[0]}</p>
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <p className={`${cascading.whtclr} mb-2`}>Size</p>
                                    <p className={`${cascading.whtclr} mb-2 ms-5`}>Price</p>
                                </div>
                                
                                {mapdata[symbols?.[0]?.toLowerCase()] && mapdata?.[symbols?.[0]?.toLowerCase()]?.asks && mapdata?.[symbols?.[0]?.toLowerCase()].asks?.length > 0 && mapdata?.[symbols?.[0]?.toLowerCase()].asks?.reverse().slice(0,10).map((e, i) =>
                                <div className='ggclr mt-0 py-2'>
                           
                                <div className='d-flex justify-content-center'>
                                        <p className={`${cascading.redclr} mb-0`}>{e?.[1]}</p>
                                        <p className={`${cascading.redclr} mb-0 ms-3`}>{e?.[0]}</p>
                                    </div>
                                </div>
                                )}
                                {mapdata[symbols?.[0]?.toLowerCase()] &&  mapdata?.[symbols?.[0]?.toLowerCase()].bids?.length > 0 && mapdata?.[symbols?.[0]?.toLowerCase()].bids?.reverse().slice(0,10).map((e, i) =>
                                <div className='ggclr mt-0 py-2'>
                           
                                <div className='d-flex justify-content-center'>
                                        <p className={`${cascading.grnclr} mb-0`}>{e?.[1]}</p>
                                        <p className={`${cascading.grnclr} mb-0 ms-3`}>{e?.[0]}</p>
                                    </div>
                                </div>
                                )}

                            
                    </div>
                    <div className='col-11 col-sm-6 col-md-4'>
                    <div className='text-center'>
                                    <p className={`${cascading.whtclr} mb-0`}>Binance</p>
                                    <p className={`${cascading.grayclr} ${cascading.f12} mb-3`}>{symbols[1]}</p>
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <p className={`${cascading.whtclr} mb-2`}>Size</p>
                                    <p className={`${cascading.whtclr} mb-2 ms-5`}>Price</p>
                                </div>
                                {mapdata[symbols?.[1]?.toLowerCase()] && mapdata?.[symbols?.[1]?.toLowerCase()].asks &&  mapdata?.[symbols?.[1]?.toLowerCase()].asks?.length > 0 && mapdata?.[symbols?.[1]?.toLowerCase()].asks?.reverse().slice(0,10).map((e, i) =>
                                <div className='ggclr mt-0 py-2'>
                           
                                <div className='d-flex justify-content-center'>
                                        <p className={`${cascading.redclr} mb-0`}>{e?.[1]}</p>
                                        <p className={`${cascading.redclr} mb-0 ms-3`}>{e?.[0]}</p>
                                    </div>
                                </div>
                                )}
                                {mapdata[symbols?.[1]?.toLowerCase()] && mapdata?.[symbols?.[1]?.toLowerCase()].bids && mapdata?.[symbols?.[1]?.toLowerCase()].bids?.length > 0 && mapdata?.[symbols?.[1]?.toLowerCase()].bids?.reverse().slice(0,10).map((e, i) =>
                                <div className='ggclr mt-0 py-2'>
                           
                                <div className='d-flex justify-content-center'>
                                        <p className={`${cascading.grnclr} mb-0`}>{e?.[1]}</p>
                                        <p className={`${cascading.grnclr} mb-0 ms-3`}>{e?.[0]}</p>
                                    </div>
                                </div>
                                )}

                            
                    </div>
                    <div className='col-11 col-sm-6 col-md-4'>
                    <div className='text-center'>
                                    <p className={`${cascading.whtclr} mb-0`}>Binance</p>
                                    <p className={`${cascading.grayclr} ${cascading.f12} mb-3`}>{symbols[2]}</p>
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <p className={`${cascading.whtclr} mb-2`}>Size</p>
                                    <p className={`${cascading.whtclr} mb-2 ms-5`}>Price</p>
                                </div>
                                {mapdata[symbols?.[2]?.toLowerCase()] && mapdata?.[symbols?.[2]?.toLowerCase()].asks && mapdata?.[symbols?.[2]?.toLowerCase()].asks?.length > 0 && mapdata?.[symbols?.[2]?.toLowerCase()].asks?.reverse().slice(0,10).map((e, i) =>
                                <div className='ggclr mt-0 py-2'>
                           
                                <div className='d-flex justify-content-center'>
                                        <p className={`${cascading.redclr} mb-0`}>{e?.[1]}</p>
                                        <p className={`${cascading.redclr} mb-0 ms-3`}>{e?.[0]}</p>
                                    </div>
                                </div>
                                )}
                                {mapdata[String(symbols[2]).toLowerCase()] && mapdata?.[String(symbols[2]).toLowerCase()].bids && mapdata?.[String(symbols[2]).toLowerCase()].bids?.length > 0 && mapdata?.[symbols?.[2]?.toLowerCase()].bids?.reverse().slice(0,10).map((e, i) =>
                                <div className='ggclr mt-0 py-2'>
                           
                                <div className='d-flex justify-content-center'>
                                        <p className={`${cascading.grnclr} mb-0`}>{e?.[1]}</p>
                                        <p className={`${cascading.grnclr} mb-0 ms-3`}>{e?.[0]}</p>
                                    </div>
                                </div>
                                )}
                            
                    </div>
                </div>




                {/* <Table responsive>
                    <thead>
                        <tr>
                            <th>
                                <div>
                                    <p className={`${cascading.whtclr} mb-0`}>Binance</p>
                                    <p className={`${cascading.grayclr} ${cascading.f12} mb-3`}>{symbols[0]}</p>
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <p className={`${cascading.whtclr} mb-0`}>Size</p>
                                    <p className={`${cascading.whtclr} mb-0 ms-5`}>Price</p>
                                </div>
                            </th>
                            <th>
                                <div>
                                    <p className={`${cascading.whtclr} mb-0`}>Binance</p>
                                    <p className={`${cascading.grayclr} ${cascading.f12} mb-3`}>{symbols[1]}</p>
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <p className={`${cascading.whtclr} mb-0`}>Size</p>
                                    <p className={`${cascading.whtclr} mb-0 ms-5`}>Price</p>
                                </div>
                            </th>
                            <th>
                                <div>
                                    <p className={`${cascading.whtclr} mb-0`}>Binance</p>
                                    <p className={`${cascading.grayclr} ${cascading.f12} mb-3`}>{symbols[2]}</p>
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <p className={`${cascading.whtclr} mb-0`}>Size</p>
                                    <p className={`${cascading.whtclr} mb-0 ms-5`}>Price</p>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>

                        {redcolor.map((e, i) =>
                            <tr>
                                <td>
                                    <div className='d-flex justify-content-center'>
                                        <p className={`${cascading.redclr} mb-0`}>10.00033000</p>
                                        <p className={`${cascading.redclr} mb-0 ms-3`}>27412.28000000</p>
                                    </div>
                                </td>
                                <td>
                                    <div className='d-flex justify-content-center'>
                                        <p className={`${cascading.redclr} mb-0`}>20.00033000</p>
                                        <p className={`${cascading.redclr} mb-0 ms-3`}>27412.28000000</p>
                                    </div>
                                </td>
                                <td>
                                    <div className='d-flex justify-content-center'>
                                        <p className={`${cascading.redclr} mb-0`}>30.00033000</p>
                                        <p className={`${cascading.redclr} mb-0 ms-3`}>27412.28000000</p>
                                    </div>
                                </td>
                            </tr>
                        )}
                        {redcolor.map((e, i) =>
                            <tr>
                                <td>
                                    <div className='d-flex justify-content-center'>
                                        <p className={`${cascading.yelclr} mb-0`}>Spread</p>
                                        <p className={`${cascading.yelclr} mb-0 ms-3`}>0.00001</p>
                                    </div>
                                </td>
                                <td>
                                    <div className='d-flex justify-content-center'>
                                        <p className={`${cascading.yelclr} mb-0`}>Spread</p>
                                        <p className={`${cascading.yelclr} mb-0 ms-3`}>0.0001</p>
                                    </div>
                                </td>
                                <td>
                                    <div className='d-flex justify-content-center'>
                                        <p className={`${cascading.yelclr} mb-0`}>Spread</p>
                                        <p className={`${cascading.yelclr} mb-0 ms-3`}>0.001</p>
                                    </div>
                                </td>
                            </tr>
                        )}
                         {redcolor.map((e, i) =>
                            <tr>
                                <td>
                                    <div className='d-flex justify-content-center'>
                                        <p className={`${cascading.grnclr} mb-0`}>22.89036000</p>
                                        <p className={`${cascading.grnclr} mb-0 ms-3`}>27412.15000000</p>
                                    </div>
                                </td>
                                <td>
                                    <div className='d-flex justify-content-center'>
                                        <p className={`${cascading.grnclr} mb-0`}>23.89036000</p>
                                        <p className={`${cascading.grnclr} mb-0 ms-3`}>0.5790000</p>
                                    </div>
                                </td>
                                <td>
                                    <div className='d-flex justify-content-center'>
                                        <p className={`${cascading.grnclr} mb-0`}>24.89036000</p>
                                        <p className={`${cascading.grnclr} mb-0 ms-3`}>0.5790000</p>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table> */}


            </div>





        </>


    )
}

export default Orderbooktable