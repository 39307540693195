import React, { useState } from 'react'
import cascading from '../assests/css/multiarbicard.module.css'
import { Col, Nav, Row, Tab, Form } from 'react-bootstrap';
import droparrow from '../assests/images/dropdown.svg';
import bnc from '../assests/images/bnc.svg'
import Switch from "react-switch";

function Multiarbicard({data , pair1,pair2,pair3,type}) {
    const [check, setCheck] = useState(false);
    const [checked, setChecked] = useState(false);
    
    const handleChange = nextChecked => {
        setChecked(nextChecked);
    };

    function verifyCheck() {
        if (check) {
            setCheck(false)
        } else {
            setCheck(true)
        }
    }
    return (

        <div className={`${cascading.cardlist}`}>
            <div className={`${cascading.cardflex} align-items-center justify-content-between py-3 px-2 px-sm-3`}>
                <div>
                    <p className={`${cascading.buycolor} mb-0`}>{type[0]}</p>
                    <p className={`${cascading.whtclr} mb-0`}>{pair1?.baseAsset}</p>
                </div>
                <div>
                    <p className={`${cascading.whtclr} mb-0`}>50268 {pair1?.baseAsset}<br />
                        {pair1.markup_price} USDT <span><img src={droparrow} alt='logo' className={`img-fluid ${cascading.droparrow}`} /></span></p>
                    <p className={`${cascading.grayclr} mt-1 mb-0`}>{type[0] == "BUY" ? pair1?.market_price : (1/pair1?.market_price).toFixed(5) } USDT per {pair1?.baseAsset}</p>
                </div>
                <div>
                    <img src={bnc} alt='logo' className={`img-fluid ${cascading.bncimg}`} />
                    <p className={`${cascading.whtclr} mb-0`}>Binance</p>
                    <p className={`${cascading.grayclr} mb-0`}>USDT Market</p>
                </div>
            </div>
            <div className={`${cascading.cardflex} align-items-center justify-content-between py-3 px-2 px-sm-3`}>
                <div>
                    <p className={`${cascading.buycolor} mb-0`}>{type[1]}</p>
                    <p className={`${cascading.whtclr} mb-0`}>{ pair2?.baseAsset}</p>
                </div>
                <div>
                    <p className={`${cascading.whtclr} mb-0`}>50268 BUSD<br />
                    {pair2.markup_price} USDT <span><img src={droparrow} alt='logo' className={`img-fluid ${cascading.droparrow}`} /></span></p>
                    <p className={`${cascading.grayclr} mt-1 mb-0`}>{type[0] == "BUY" ? pair2?.market_price : (1/pair2?.market_price).toFixed(5) } USDT per BUSD</p>
                </div>
                <div>
                    <img src={bnc} alt='logo' className={`img-fluid ${cascading.bncimg}`} />
                    <p className={`${cascading.whtclr} mb-0`}>Binance</p>
                    <p className={`${cascading.grayclr} mb-0`}>USDT Market</p>
                </div>
            </div>
            <div className={`${cascading.cardflex} align-items-center justify-content-between py-3 px-2 px-sm-3`}>
                <div>
                    <p className={`${cascading.redcolor} mb-0`}>{type[2]}</p>
                    <p className={`${cascading.whtclr} mb-0`}>{ pair3?.baseAsset}</p>
                </div>
                <div>
                    <p className={`${cascading.whtclr} mb-0`}>50268 BUSD<br />
                    {pair2.markup_price} USDT <span><img src={droparrow} alt='logo' className={`img-fluid ${cascading.droparrow}`} /></span></p>
                    <p className={`${cascading.grayclr} mt-1 mb-0`}>{type[0] == "BUY" ? pair3?.market_price : (1/pair3?.market_price).toFixed(5) } USDT per BUSD</p>
                </div>
                <div>
                    <img src={bnc} alt='logo' className={`img-fluid ${cascading.bncimg}`} />
                    <p className={`${cascading.whtclr} mb-0`}>Binance</p>
                    <p className={`${cascading.grayclr} mb-0`}>USDT Market</p>
                </div>
            </div>
            <div className={`${cascading.cardflex} ${cascading.cardgreen} align-items-center justify-content-between py-3 px-1 px-sm-2`}>
                {/* <Switch
                    onChange={handleChange}
                    checked={checked}
                    offColor="#162019"
                    onColor="#25C852"
                    offHandleColor="#ffff"
                    onHandleColor="#fff"
                    height={20}
                    width={36}
                    handleDiameter={18}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    className="react-switch"
                    id="material-switch"
                /> */}
                <span><img src={bnc} alt='logo' className={`img-fluid ${cascading.bncimg}`} /><p className={`${cascading.whtclr} mb-0`}>Binance</p></span>
                {/* <p className={`${cascading.whtclr} react-switch`}>{`Profit`}</p> */}
                <p className={`${cascading.whtclr} mb-0`}>{(data?.profit).toFixed(5)} USDT</p>
                <button className={`${cascading.whtbtn} btn`}><span className={`${cascading.timelinecircle} me-2`}></span>Track Live</button>
            </div>
        </div>
    )
}

export default Multiarbicard;