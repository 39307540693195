const Envname = "demo"

let key = {};
key.KEY = 'CardBo@rD1290%6Fine3'
key.ONEDAYINSECONDS = 0
key.BLOCKS_PER_YEAR = 0
key.SECRET_KEY = "Su11@mArb!trag3"
key.RPAD_ADDRESS = ''
key.ROUTER = ''
key.EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
key.MOBILE = /^\d{10}$/
key.NumOnly = /^\d+$/
key.PASSWORD = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
key.OnlyAlbhabets = /^(?:[A-Za-z]+)(?:[A-Za-z0-9 _]*)$/
key.notSpecil = /^[a-zA-Z-9]+$/
key.OnlyAlphSpecial = /^[A-Za-z_@.#&+-]*$/
key.IPFS = 'https://ipfs.io/ipfs/'
key.limit = 50
key.NumDigitOnly = /[^0-9\.]/g
key.NumberOnly = /[^0-9]/g


if (Envname === "local") {

    key.COMMON_URL = 'http://localhost:7000/common'
    key.BACK_URL = 'http://localhost:7000/front'
    key.ADMIN_URL = 'http://localhost:7000/admin'
    key.TRADE_URL = 'http://localhost:7000/trade'
    key.USDT ="0x54C09A5856B08D795079cB1Ca61f55afD85Be39a"
    key.Admin= '0x025c1667471685c323808647299e5DbF9d6AdcC9'
    key.IMG_URL = 'http://localhost:7000'
    key.chain_Id_List = [97, 43113] // optional
    key.RPC_URL = "https://bsc-testnet-rpc.publicnode.com"
    key.CHAIN_ID = 97
    key.Block_URL = "https://testnet.bscscan.com" // optional
    key.Siteurl = 'http://localhost:3000' //optional
    key.BaseUrl = 'https://testnet.binance.vision' //optional
    key.socket_url = 'http://localhost:7000/'
}

if (Envname === "demo") {
    
    key.COMMON_URL = 'https://sullambackend.maticz.in/common'
    key.BACK_URL = 'https://sullambackend.maticz.in/front'
    key.ADMIN_URL = 'https://sullambackend.maticz.in/admin'
    key.TRADE_URL = 'https://sullambackend.maticz.in/trade'
    key.USDT ="0x54C09A5856B08D795079cB1Ca61f55afD85Be39a"
    key.Admin= '0x025c1667471685c323808647299e5DbF9d6AdcC9'
    key.IMG_URL = 'https://sullambackend.maticz.in/'
    key.RPC_URL = "https://bsc-testnet-rpc.publicnode.com"
    key.CHAIN_ID = 97
    key.chain_Id_List = [97, 43113]//optional
    key.Block_URL = "https://testnet.bscscan.com"//optional
    key.Siteurl = 'https://sullamdev.maticz.in'//optional
    key.BaseUrl = 'https://testnet.binance.vision'//optional
    key.socket_url = ''
}

if (Envname === "demolive") {
    
    key.COMMON_URL = 'https://backend-sullam.maticz.in/common'
    key.BACK_URL = 'https://backend-sullam.maticz.in/front'
    key.ADMIN_URL = 'https://backend-sullam.maticz.in/admin'
    key.TRADE_URL = 'https://backend-sullam.maticz.in/trade'
    key.USDT ="0x55d398326f99059fF775485246999027B3197955"
    key.Admin= '0x620C1DE5c7cc2a676eBEb2f32dBE0D4C757879d4'
    key.IMG_URL = 'https://backend-sullam.maticz.in'
    key.RPC_URL = "https://bsc-dataseed1.binance.org/"
    key.CHAIN_ID = 56
    key.chain_Id_List = [97, 43113]//optional
    key.Block_URL = "https://bscscan.com"//optional
    key.Siteurl = 'https://sullam.maticz.in'//optional
    key.BaseUrl = 'https://api4.binance.com'//optional
    key.socket_url = 'wss://ws-api.binance.com:443/ws-api/v3'
}

if (Envname === "b_live") {
    
    key.COMMON_URL = 'https://sullam-bd-backend.maticz.in/common'
    key.BACK_URL = 'https://sullam-bd-backend.maticz.in/front'
    key.ADMIN_URL = 'https://sullam-bd-backend.maticz.in/admin'
    key.TRADE_URL = 'https://sullam-bd-backend.maticz.in/trade'
    key.USDT ="0x55d398326f99059fF775485246999027B3197955"
    key.Admin= '0x620C1DE5c7cc2a676eBEb2f32dBE0D4C757879d4'
    key.IMG_URL = 'https://sullam-bd-backend.maticz.in/'
    key.RPC_URL = "https://bsc-dataseed1.binance.org/"
    key.CHAIN_ID = 56
    key.chain_Id_List = [97, 43113]//optional
    key.Block_URL = "https://bscscan.com"//optional
    key.Siteurl = 'https://sullam.maticz.in'//optional
    key.BaseUrl = 'https://api4.binance.com'//optional
    key.socket_url = 'wss://ws-api.binance.com:443/ws-api/v3'
}


if (Envname === "production") {
    
    key.COMMON_URL = 'https://backend-sullam.maticz.in/common'
    key.BACK_URL = 'https://backend-sullam.maticz.in/front'
    key.ADMIN_URL = 'https://backend-sullam.maticz.in/admin'
    key.TRADE_URL = 'https://backend-sullam.maticz.in/trade'
    key.USDT ="0x2B90E061a517dB2BbD7E39Ef7F733Fd234B494CA"
    key.Admin= '0x025c1667471685c323808647299e5DbF9d6AdcC9'
    key.IMG_URL = 'https://backend-sullam.maticz.in'
    key.RPC_URL = "https://bsc-dataseed1.binance.org/"
    key.CHAIN_ID = 56
    key.chain_Id_List = [97, 43113]//optional
    key.Block_URL = "https://testnet.bscscan.com"//optional
    key.Siteurl = 'https://sullam.maticz.in/'//optional
    key.BaseUrl = 'https://api4.binance.com'//optional
    key.socket_url = 'wss://ws-api.binance.com:443/ws-api/v3'
}
export default key