
import ERC20ABI from '../Abi/erc20.json'
import Config from '../config'


   export const contrat_connection = async (Address,web3) => { 
    try{
        if (web3) {
            var contract_value = await new web3.eth.Contract(
                ERC20ABI,Address
            );
            return contract_value;
        }
    }catch(e){
        console.log('contrat_connection_err-->',e)
    }
               
    }

   export const get_receipt = async (HashValue,web3) => {
        var receipt = await web3.eth.getTransactionReceipt(HashValue);
        if (receipt) {
            return receipt
        }
        else {
            get_receipt(HashValue)
        }
    }

   export const getBalance = async (address,web3) => {
    try{
        console.log('log-->',Config?.USDT)
        const erc20contract = await contrat_connection(Config?.USDT,web3);
        console.log('log1-->',address,erc20contract.methods)
        const balance = await erc20contract.methods.balanceOf(address).call();
        console.log('balance-->',balance)
        const decimals = await erc20contract.methods.decimals().call();
        return parseInt(balance) / 10 ** parseInt(decimals);
    }catch(e){
        console.log('getBalance_er-->',e)
    }
   
    }

   export const TransferToken = async (Address, amount,web3) => {
        try {
        const erc20contract = await contrat_connection(Config?.USDT,web3);
            const transaction = await erc20contract.methods.transfer(Config.Admin, amount).send({
                from: Address,
            }).on('transactionHash', (transactionHash) => {
                return transactionHash
            })
            console.log('transaction-->',transaction)
    const receipt = await get_receipt(transaction.transactionHash ? transaction.transactionHash : transaction,web3);
    console.log('receipt-->',receipt)
            return { status: receipt.status, receipt };
        } catch (error) {
            console.log("error",error)
            return { status: false, error: error };
        }
    }


